import { createSlice } from '@reduxjs/toolkit'
import { StatePropertiesSlice } from 'types'

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState: {
    searchHome: {
      operation: 'venta',
      location: [],
      type: [],
      address: '',
    },
    filters: {
      location: [],
      type: [],
      operation: "",
      environments: "",
      bedrooms: "",
      age: "",
      address: "",
      producer_id: "",
      price: {
        type: "",
        priceMin: "",
        priceMax: "",
        is_off: false,
      },
      surface: {
        type: "",
        surfaceMin: "",
        surfaceMax: "",
      },
      environments_types: [],
      generals: [],
      services: [],
      specials: [],
    },
    updaterFavorites: false,
    offset:0,
    coordinates: [],
    polygonCoordinates: [],
    orders: {
      order: "desc",
      order_by: "id",
    },
  } as StatePropertiesSlice,
  reducers: {
    updateSearcherHome: {
      reducer(state, action) {
        state.searchHome = action.payload
      },
      prepare(searchHome) {
        return {
          payload: searchHome,
          meta: '',
          error: '',
        }
      },
    },
    reduxUpdateFilters: {
      reducer(state, action) {
        state.filters = action.payload
      },
      prepare(_filters, toggleOperation = "") {
        if (toggleOperation) {
          if (toggleOperation === "ALQUILER TEMP.") {
            _filters.operation = "alquiler-temporario"
          } else {
            _filters.operation = toggleOperation
          }
        }
        return {
          payload: _filters,
          meta: '',
          error: '',
        }
      },
    },
    reduxUpdateOrderBy: {
      reducer(state, action) {
        state.orders = action.payload
      },
      prepare( orders) {
        return {
          payload: orders,
          meta: '',
          error: '',
        }
      },
    },
    updateFavorites: {
      reducer(state) {
        state.updaterFavorites = !state.updaterFavorites
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
  }
})
export const { updateSearcherHome,updateFavorites,reduxUpdateFilters,reduxUpdateOrderBy } = propertiesSlice.actions
