import { orderNovelties } from '@helpers/helper.novelty'
import { mediacoreApi } from '../mediacoreApi'
import { ResponseSummaryBlog } from 'types'
const blogExtended = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query<ResponseSummaryBlog, void>({
      query: () => `blog/novedades/get_data_summary/?client=${process.env.GATSBY_CLIENTID}`,
      transformResponse: (data: ResponseSummaryBlog) => ({
        news: orderNovelties(data.news),
        tags: data.tags,
      }),
    }),
    getDetailBlog: builder.query({
      query: ({ BLOG_ID }) =>
        `blog/novedades/${BLOG_ID}/get_data_detail/?client=${process.env.GATSBY_CLIENTID}`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetBlogsQuery, useGetDetailBlogQuery } = blogExtended
