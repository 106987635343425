import { ObjectTokkoFilters } from 'types.js'
import { originalTokkoApi } from '../originalTokkoApi'

import { getArrayOperationById, getSumaryFilterDefault } from '@helpers/helper.filters'

interface Params {
  API_KEY?: string
  OPERATION?: string
  TYPE?: number[]
}

const propertyExtended = originalTokkoApi.injectEndpoints({
  endpoints: (builder) => ({
    getTotalFilters: builder.query<ObjectTokkoFilters, Params>({
      query: ({ API_KEY, OPERATION = '', TYPE = [] }) => ({
        url: `property/get_search_summary/?key=${API_KEY}&lang=es_ar&format=json&data=${encodeURI(
          getSumaryFilterDefault({
            operation: getArrayOperationById(OPERATION),
            property_types: TYPE,
          }),
        )}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetTotalFiltersQuery } = propertyExtended
