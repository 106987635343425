import { useGetTotalFiltersQuery as useGetTotalFiltersQueryTokko } from '@apis/originalTokkoApi/properties'
import { useGetSummaryQuery as useGetTotalFiltersQueryMediacore } from '@apis/mediacore/properties'

interface Props {
  OPERATION?: string
  TYPE?: any
  API_KEY?: string
}

function useQuerySummary(props?: Props) {
  const {
    data: allSummaryData,
    isLoading,
    isError,
    isFetching,
  } = props?.API_KEY
    ? useGetTotalFiltersQueryTokko({
        API_KEY: props.API_KEY,
        OPERATION: props?.OPERATION,
        TYPE: props?.TYPE,
      })
    : useGetTotalFiltersQueryMediacore({
        CLIENT_ID: process.env.GATSBY_CLIENTID,
        OPERATION: props?.OPERATION,
        TYPE: props?.TYPE,
      })

  return {
    allSummaryData,
    isLoading,
    isError,
    isFetching,
    isMediacore: props?.API_KEY ? false : true,
  }
}

export default useQuerySummary
