import { Development } from "types.js"
import { middlewareTokkoApi } from "../middlewareTokkoApi"

const developmentExtended = middlewareTokkoApi.injectEndpoints({
  endpoints: builder => ({
    getDevelopments: builder.query<Development[], string>({
      query: (API_KEY) =>({
        url: `developments/search?cache=0`,
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        method: 'GET'
      }), 
      transformResponse: ({objects}) => objects,
    }),
    getDevelopmentDetail: builder.query({
      query: ({API_KEY,DEVELOPMENT_ID}) =>({
        url: `developments/${DEVELOPMENT_ID}?cache=0`,
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        method: 'GET'
      }), 
    }),
    getUnities: builder.query({
      query: ({API_KEY,DEVELOPMENT_ID}) =>({
        url: `properties/search?development_id=${DEVELOPMENT_ID}&limit=1000&cache=1&save=1`,
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        method: 'GET'
      }), 
      transformResponse: ({objects}) => objects,
    }),
  }),
  overrideExisting: false,
})


export const { useGetDevelopmentsQuery,useGetDevelopmentDetailQuery, useGetUnitiesQuery } = developmentExtended
