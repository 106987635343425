import { Development, Property } from "types"

export const makeJsonContact = ({
  name,
  cellphone,
  email,
  text,
  tags,
  property,
  development,
}: {
  name?: string
  cellphone?: string
  email?: string
  text?: string
  tags?: string[]
  property?: Property
  development?: Development
}) => {
    const body = JSON.stringify({
        name: name,
        cellphone: cellphone,
        email: email,
        text: `- Nueva Consulta - \n Nombre: ${name || 'Sin definir'} \n Teléfono: ${cellphone || 'Sin definir'} \n Email: ${email} \n Mensaje: ${text || 'Sin definir'}`,
        tags,
        properties:property?.id,
        developments:development?.id
      })
      return body
}
