import React, { useState, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { RealEstateDataFooterData, Button } from 'types'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { getMessageWhatsapp, scrollToElement } from '@helpers/helper.rendering'
import useQueryDevelopment from '@hooks/useQueryDevelopment'
import useQueryProperty from '@hooks/useQueryProperty'

interface ExternalProps {
  data: any
  from: string
  title: string
  paragraph: string
  underlineMode: boolean
  form: any
  button: Button
  property_id?: string
  development_id?: string
}

const isBrowser = typeof window !== 'undefined'

type CombinedProps = RealEstateDataFooterData & ExternalProps
const Footer2 = ({
  titles,
  data,
  from,
  title,
  paragraph,
  underlineMode,
  form,
  button,
  global_data,
  development_id,
  property_id,
}: CombinedProps) => {
  const getCurrentYear = () => new Date().getFullYear()

  const tokkoKey = global_data.pageConfig.keys.tokko
  const development = useQueryDevelopment(development_id, tokkoKey)
  const property = useQueryProperty(property_id, tokkoKey)
  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const social = global_data.pageConfig.social
  const college = global_data.college
  const [closeWhatsapp, setCloseWhatsapp] = useState(true)
  const icons = global_data?.pageConfig.design.icons || 'Solid'
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )

  const branchesWa = false

  const get_whatsApp_element = () => {
    if (isBrowser) {
      if (branchesWa) {
        if (branch_office?.length === 0) {
          return (
            <>
              <a
                href={
                  'https://web.whatsapp.com/send?text=' +
                  getMessageWhatsapp(development?.data, property?.data, location?.href) +
                  '&phone=+' +
                  branch_office[0]?.contact_data.phonesLine[0].replaceAll('-', '')
                }
                target="_blank"
                className={'icon-whatsapp contact-btn d-none d-lg-flex '}
              ></a>
              <a
                href={
                  'https://api.whatsapp.com/send?text=' +
                  getMessageWhatsapp(development?.data, property?.data, location?.href) +
                  '&phone=+' +
                  branch_office[0]?.contact_data.phonesLine[0].replaceAll('-', '')
                }
                target="_blank"
                className={'icon-whatsapp contact-btn d-flex d-lg-none '}
              ></a>
            </>
          )
        } else {
          return (
            <>
              <div
                onClick={() => setCloseWhatsapp(!closeWhatsapp)}
                className={
                  'icon-whatsapp contact-btn d-lg-flex div-whatsapp ' +
                  (closeWhatsapp ? 'close' : 'open')
                }
              >
                <div
                  className={'float-whatsapp ' + (branch_office?.length === 1 ? 'only-one' : '')}
                >
                  {branch_office?.slice(0, 2).map(
                    (element, index) =>
                      element.contact_data.phonesLine.length > 0 && (
                        <Fragment key={index}>
                          <a
                            key={index}
                            href={
                              'https://web.whatsapp.com/send?text=' +
                              getMessageWhatsapp(
                                development?.data,
                                property?.data,
                                location?.href,
                              ) +
                              '&phone=+' +
                              element.contact_data.phonesLine[0].replaceAll('-', '')
                            }
                            target="_blank"
                            className={'contact-btn d-none d-lg-flex '}
                          >
                            {element.name}
                          </a>
                          <a
                            href={
                              'https://api.whatsapp.com/send?text=' +
                              getMessageWhatsapp(
                                development?.data,
                                property?.data,
                                location?.href,
                              ) +
                              '&phone=+' +
                              element.contact_data.phonesLine[0].replaceAll('-', '')
                            }
                            target="_blank"
                            className={'contact-btn d-flex d-lg-none '}
                          >
                            {element.name}
                          </a>
                        </Fragment>
                      ),
                  )}
                </div>
              </div>
            </>
          )
        }
      } else {
        return (
          <>
            <a
              href={
                'https://web.whatsapp.com/send?text=' +
                getMessageWhatsapp(development?.data, property?.data, location?.href) +
                '&phone=+' +
                contact_data?.phoneMobile![0]?.replaceAll('-', '')
              }
              target="_blank"
              className={'icon-whatsapp contact-btn d-none d-lg-flex '}
            ></a>
            <a
              href={
                'https://api.whatsapp.com/send?text=' +
                getMessageWhatsapp(development?.data, property?.data, location?.href) +
                '&phone=+' +
                contact_data?.phoneMobile![0]?.replaceAll('-', '')
              }
              target="_blank"
              className={'icon-whatsapp contact-btn d-flex d-lg-none '}
            ></a>
          </>
        )
      }
    }
  }

  const scrollTop = () => {
    if (isBrowser) {
      window?.scrollTo(0, 0)
    }
  }

  const FormComponent = require(`@components/form/1`)?.default

  const CopyEl = () => (
    <>
      <p className="copy text-center text-lg-start">
        Promoted by{' '}
        <a
          target="_blank"
          className="normal-color"
          href="https://mediahaus.com.ar"
        >
          {' '}
          MediaHaus
        </a>{' '}
        - Powered by{' '}
        <a
          target="_blank"
          href="https://mediacore.app/"
        >
          {' '}
          MediaCore
        </a>
         {/* <br /> */}
        <span className="small-text">- Todos los derechos reservados ® {getCurrentYear()}</span>
      </p>
    </>
  )

  // Prop to show scrollTop button
  const hasScrollTop = false

  return (
    <footer className="footer-b position-relative">
      {hasScrollTop ? (
        <button
          onClick={() => {
            scrollToElement('header')
            scrollTop()
          }}
          className="btn goTop-btn d-flex align-items-center justify-content-center"
        >
          <i className="icon-arrow"></i>
        </button>
      ) : null}
      <Container fluid>
        <Row className="contacto-main p-y-config">
          <Col
            lg={6}
            className="intro mx-auto"
          >
            <h5 className="text-uppercase mb-4 text-center text-lg-start">
              {title ? title : `Contacto`}
            </h5>
            <p className="mb-5 text-center text-lg-start">
              {paragraph
                ? paragraph
                : `Con gusto responderemos todas tus dudas y te guiaremos durante el proceso.`}
            </p>
            <FormComponent
              data={data}
              underlineMode={underlineMode}
              from={from}
              button={button}
              global_data={global_data}
              captcha={form?.captcha}
            />
            <div id="alert-message"></div>
            <div className="d-none d-lg-block">{CopyEl()}</div>
          </Col>
          <Col
            lg={{ span: 4, offset: 2 }}
            className="data-column"
          >
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ order: 1 }}
              className="mb-lg-5 mb-0"
            >
              <div className="office-wrapper mb-5 text-center text-lg-start">
                {branch_office?.length > 0 && (
                  <>
                    <h5 className="text-uppercase mb-4">
                      {titles?.branch_office && branch_office[0]?.name
                        ? `${titles?.branch_office} ${branch_office[0]?.name}`
                        : 'Oficina'}
                    </h5>
                    <p>{branch_office[0]?.address} </p>
                    <p>
                      {branch_office[0]?.city}, {branch_office[0]?.province}
                    </p>
                    {branch_office[0]?.contact_data?.phonesLine?.map((phone, index) => (
                      <p key={index}>Teléfono {phone.replace('54911', '54 9 11 ')}</p>
                    ))}
                    {branch_office[0]?.contact_data?.phonesMobile?.map((whatsapp, index) => (
                      <a
                        key={index}
                        className={'hover-footer-link'}
                        target="_blank"
                        href={
                          'https://api.whatsapp.com/send?' + 'phone=' + whatsapp.replaceAll('-', '')
                        }
                      >
                        + {whatsapp.replace('54911', '54 9 11 ')}
                      </a>
                    ))}
                    <p className={branch_office[0]?.college === '' ? 'd-none' : ''}>
                      Matrícula {branch_office[0]?.college}
                    </p>
                    <p className={branch_office[0]?.opening_hours === '' ? 'd-none' : ''}>
                      {branch_office[0]?.opening_hours}
                    </p>
                    {branch_office[0]?.contact_data?.emails?.map((email, index) => (
                      <a
                        key={index}
                        target="_blank"
                        className={'hover-footer-link '}
                        href={'mailto:' + email}
                      >
                        {email}
                      </a>
                    ))}
                  </>
                )}
                {contact_data?.phoneLine?.map((phone, index) => (
                  <Fragment key={index}>
                    {phone && <p key={index}>Teléfono {phone.replace('54911', '54 9 11 ')}</p>}
                  </Fragment>
                ))}
                {contact_data?.phoneMobile?.map((whatsapp, index) => (
                  <p key={index}>
                    <a
                      className={'hover-footer-link d-block'}
                      target="_blank"
                      href={
                        'https://api.whatsapp.com/send?' + 'phone=' + whatsapp.replaceAll('-', '')
                      }
                    >
                      +{whatsapp.replace('54911', '54 9 11 ')}
                    </a>
                  </p>
                ))}
                {contact_data?.email && (
                  <p>
                    <a
                      target="_blank"
                      className={'hover-footer-link d-block'}
                      href={'mailto:' + contact_data?.email}
                    >
                      {contact_data?.email}
                    </a>
                  </p>
                )}
                {college && (
                  <p>
                    <a className="d-block">{college}</a>
                  </p>
                )}
              </div>
              <div className="investor-wrapper my-space">
                <div
                  className={
                    'item-contact row mb-0 button-separator ' +
                    (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                      ? 'd-block'
                      : ' d-none')
                  }
                >
                  <h5 className="text-uppercase mb-4 text-center text-lg-start">
                    {titles?.modules}
                  </h5>
                  {INVESTOR_CONFIGURATION?.module && (
                    <div className="d-block">
                      <a
                        className="btn btn-investor mb-2 d-flex justify-content-between"
                        target={'_blank'}
                        href={INVESTOR_CONFIGURATION.url_login}
                      >
                        <span className="text-capitalize">
                          {INVESTOR_CONFIGURATION.text_cta || 'Inversores'}
                        </span>
                        <i className={'icon-investment' + iconsStyle}></i>
                      </a>
                    </div>
                  )}
                  {OWNER_CONFIGURATION?.module && (
                    <div className="d-block">
                      <a
                        className="btn btn-investor mb-2 d-flex justify-content-between"
                        target={'_blank'}
                        href={OWNER_CONFIGURATION.url_login}
                      >
                        <span className="text-capitalize">
                          {OWNER_CONFIGURATION.text_cta || 'Propietarios'}
                        </span>
                        <i className={'icon-investor-access' + iconsStyle}></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="social-wrapper text-center text-lg-start">
                <Row className="item-contact mb-0">
                  <Col lg={12}>
                    {social?.instagram && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? instagram"
                        href={social?.instagram}
                        className={social?.instagram ? 'd-inline' : 'd-none'}
                      >
                        <i className={`icon-instagram${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.facebook && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? facebook"
                        href={social?.facebook}
                        className={social?.facebook ? 'd-inline ms-3' : 'd-none'}
                      >
                        <i className={`icon-facebook${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.linkedin && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? linkedin"
                        href={social?.linkedin}
                        className={social?.linkedin ? 'd-inline ms-3' : 'd-none'}
                      >
                        <i className={`icon-linkedin${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.youtube && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? youtube"
                        href={social?.youtube}
                        className={social?.youtube ? 'd-inline ms-3' : 'd-none'}
                      >
                        <i className={`icon-youtube${iconsStyle}`}></i>
                      </a>
                    )}
                    {social?.twitter && (
                      <a
                        target="_blank"
                        aria-label="Lea más en la red social? twitter"
                        href={social?.twitter}
                        className={social?.twitter ? 'd-inline ms-3' : 'd-none'}
                      >
                        <i className={`icon-twitter${iconsStyle}`}></i>
                      </a>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="d-block d-lg-none">{CopyEl()}</div>
            </Col>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer2
