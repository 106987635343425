import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { makeItemsNav } from '@helpers/helper.rendering'
import useQuerySummary from '@hooks/useQuerySummary'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Container, Nav } from 'react-bootstrap'
import { RealEstateDataHeaderData } from 'types'
import { useLocation } from '@reach/router'
import './index.scss'
import toast from 'react-hot-toast'

const Boilerplate_Header_1 = (props: RealEstateDataHeaderData) => {
  const { pages, global_data, hiddenLogo, leftSide, buttonCompare, divisions,background_secondary } = props
  const { divisionPath } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko
  const { pathname } = useLocation()

  const logo = global_data.logo
  const logoMobile = global_data.logoMobile
  const { data: allBlogData } = useGetBlogsQuery()
  const { allSummaryData } = useQuerySummary({ API_KEY })
  const icons: string = global_data.pageConfig.design.icons

  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const countFavorites = 0

  const [open, setOpen] = useState(false)

  const staticBody = () => {
    var body = document.body
    body.classList.toggle('overflow-hidden')
  }

  return (
    <header
      id="header"
      className={`blur b header-2 ${open ? 'opened' : 'closed'} ${hiddenLogo && ' hidden-logo '} ${background_secondary && 'background-secondary'}`}
    >
      <Nav className="brand">
        {process.env.GATSBY_IS_SITE_COMPILER === 'true' && (
          <div className="divisions-nav d-flex align-items-center">
            <Container
              className="d-flex align-items-center justify-lg-content-between"
              fluid
            >
              <img
                src={logo}
                alt=""
              />
              <div className="d-flex justify-content-end text-right w-100">
                {divisions?.map(({ path, title }, index) => (
                  <Link
                    key={index}
                    className={`${path === divisionPath ? 'active' : ''}`}
                    to={path}
                  >
                    {title}
                  </Link>
                ))}
              </div>
            </Container>
          </div>
        )}
        <div className="container-fluid">
          <div
            className={`row ${
              leftSide ? 'justify-content-lg-start' : 'justify-content-lg-between'
            } align-items-center'} justify-content-between`}
          >
            <div
              className={`col-7 col-lg-1 d-flex justify-content-start justify-content-lg-start ${
                leftSide ? 'order-2 order-lg-2 ' : 'order-1 order-lg-1'
              }`}
            >
              <Link
                to={'/'}
                className="logo d-none d-lg-flex align-items-center"
              >
                <img
                  className="logo-header"
                  src={logo}
                ></img>
              </Link>
              <Link
                to={'/'}
                className="logo d-lg-none"
              >
                <img
                  className="logo-header"
                  src={logoMobile}
                ></img>
              </Link>
            </div>
            <div
              className={`col-3 col-lg-10  d-flex ${
                leftSide
                  ? 'order-1 order-lg-1 justify-content-start'
                  : 'order-2 order-lg-2 justify-content-end text-right'
              }`}
            >
              <ul
                className={`nav-menu ${leftSide ? 'justify-content-start' : 'justify-content-end'}`}
              >
                {makeItemsNav(allSummaryData, allBlogData, pages)
                  .filter((page) => !page.path.includes('detalle'))
                  .map(({ path, title }, index) => (
                    <Nav.Item key={index}>
                      <Link
                        onClick={() => setOpen(false)}
                        className={
                          'text-uppercase ' +
                          ((pathname.includes(path) && pathname !== '' && path !== '/') ||
                          (path === '/' && pathname === '/')
                            ? 'active'
                            : '')
                        }
                        to={(divisionPath ?? '') + path}
                      >
                        {title}
                      </Link>
                    </Nav.Item>
                  ))}
              </ul>
              <button
                className="d-block d-lg-none"
                id="toggle-nav"
                onClick={() => {
                  setOpen(!open)
                  staticBody()
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="status_nav d-none d-lg-inline">Menú </span>
                  <span className="ms-4">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
              </button>
            </div>

            <div className="col-lg-1 order-3 order-lg-3 d-none d-lg-flex align-items-center justify-content-end">
              {
                buttonCompare?.value &&
                  allSummaryData?.objects?.operation_types?.length > 0 &&
                  (countFavorites === 0 ? (
                    <div
                      className="btn btn-white btn-compare border d-none d-lg-flex align-items-center justify-content-center"
                      onClick={() => toast.error('No tienes Propiedades favoritas seleccionadas.')}
                    >
                      {buttonCompare?.value}
                      <div className="mx-3">{countFavorites}</div>
                      <i
                        className={
                          `icon-favoritos-heart` +
                          (countFavorites < 1 && ' disabled ')
                        }
                      ></i>
                    </div>
                  ) : (
                    <div className={'hover-like d-lg-flex d-none align-items-center '}>
                      <span
                        id="count_favorites"
                        className={
                          '' +
                          (!countFavorites ? ' d-none ' : ' d-none d-lg-block ') +
                          ' animate__animated '
                        }
                      >
                        {countFavorites}
                      </span>
                      <Link
                        className="btn btn-white btn-compare border d-none d-lg-flex align-items-center justify-content-center"
                        to={buttonCompare?.link}
                      >
                        {buttonCompare?.value}
                        <i></i>
                      </Link>
                    </div>
                  ))
                //
                //   <Link
                //     className="btn btn-white btn-compare border"
                //
                //   >
                //
                //   </Link>
              }
            </div>
          </div>
        </div>
      </Nav>
    </header>
  )
}

export default Boilerplate_Header_1
