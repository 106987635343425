import { PriceTokko, Property, Development } from 'types'
import { ROUTES } from '../../routes'

const {
  SALE_VIEW_DATA: SALE,
  RENT_VIEW_DATA: RENT,
  TEMPORARY_VIEW_DATA: TEMPORARY,
} = JSON.parse(process.env.extra_data || ROUTES)

export const getOperations = (property: Property) => {
  if (property?.operations) {
    const operationsArray: string[] = []
    property?.operations.map((operation) => {
      switch (operation.operation_type.toLowerCase()) {
        case 'temporario': {
          operationsArray.push(TEMPORARY.name)
          break
        }
        case 'alquiler temporario': {
          operationsArray.push(TEMPORARY.name)
          break
        }
        case 'alquiler': {
          operationsArray.push(RENT.name)
          break
        }
        case 'venta': {
          operationsArray.push(SALE.name)
          break
        }
      }
    })
    return operationsArray
  }
  return ['']
}

export const getType = (property: Property) => {
  if (property?.type) {
    return property?.type.name
  }
  return ''
}

export const getEnvironment = (property: Property) => {
  if (property) {
    return property?.room_amount
  }
  return 0
}

export const getSurface = (property: Property) => {
  let surface = ''
  if (property?.type) {
    if (property?.type.name.toLowerCase() === 'terreno') {
      surface = property?.surface
    } else {
      surface = property?.total_surface
    }
    if (surface !== '') return surface.split('.')[0] + ' ' + property?.surface_measurement
  }
  return null
}

export const getRooms = (propert: Property) => {
  return propert?.suite_amount
}

export const getToilets = (propert: Property) => {
  return propert?.toilet_amount
}

export const getBathrooms = (propert: Property) => {
  return propert?.bathroom_amount
}

export const getSurfaces = (property: Property) => {
  const surfacesArray = {
    roofed: property?.roofed_surface.split('.')[0],
    unroofed: property?.unroofed_surface.split('.')[0],
    semiroofed_surface: property?.semiroofed_surface.split('.')[0],
    surface: property?.surface.split('.')[0],
    total_surface: property?.total_surface.split('.')[0],
    measurement: property?.surface_measurement.split('.')[0],
  }
  return surfacesArray
}

export const getLocation = (property: Property) => {
  if (property?.location) {
    return property?.location.name
  }
  return ''
}

export const getRegion = (property: Property) => {
  if (property?.location) {
    return property?.location.short_location.split(' | ')[1]
  }
  return ''
}

export const getFakeAddres = (property: Property) => {
  return property?.fake_address
}

export const getRealAddress = (property: Property) => {
  return property?.real_address
}

export const getPrices = (property: Property) => {
  // if(!property?.web_price){return ("Precio a consultar")}
  // if(property?.operations){
  //     const pricesArray:PriceTokko[] = []
  //     let obj:PriceTokko = {
  //         type:'',
  //         is_less_than_previous_price:false,
  //         period:0,
  //         period_number:0,
  //         previous_price:0,
  //         price:'',
  //         currency:'',
  //     }
  //     property?.operations.map((operation) => {
  //         obj.type = operation.operation_type
  //         obj.is_less_than_previous_price = operation.prices[0]?.is_less_than_previous_price,
  //         obj.period = operation.prices[0].period,
  //         obj.period_number = operation.prices[0].period_number,
  //         obj.previous_price = operation.prices[0].previous_price
  //         obj.price = !property?.web_price ? "Precio a consultar" : operation.prices[0].price
  //         obj.currency = !property?.web_price ? " " : operation.prices[0].currency
  //         pricesArray.push(obj)
  //     })
  //     return pricesArray;
  // }
  // return []
  if (property?.operations) {
    const pricesArray: PriceTokko[] = []
    property?.operations.map((operation) => {
      let price: any
      if (operation.prices[0]) {
        price = operation.prices[0]
      } else {
        price = operation.prices
      }
      const obj: PriceTokko = {
        type: operation.operation_type,
        is_less_than_previous_price: price.is_less_than_previous_price,
        period: price.period,
        period_number: price.period_number,
        previous_price: price.previous_price,
        currency: price.currency,
        price: !property?.web_price ? 'Precio a consultar' : price.price,
      }
      pricesArray.push(obj)
    })
    return pricesArray
  }
  return []
}

export const getTags = (property: Property) => {
  if (property?.tags) {
    return property?.tags
  }
  return []
}

export const getCustomTags = (property: Property) => {
  if (property?.custom_tags) {
    return property?.custom_tags
  }
  return []
}

export const hasGarage = (property: Property) => {
  return property?.parking_lot_amount ? true : false
}

export const countGarage = (property: Property) => {
  if (property?.parking_lot_amount) {
    if (property?.parking_lot_amount > 0) {
      return property?.parking_lot_amount
    }
    return 'No informado'
  } else {
    return 'No informado'
  }
}

export const makeLink = (property: Property, divisionPath?: string) => {
  return (
    (divisionPath ?? '') +
    '/propiedad/' +
    getOperations(property)[0].toLowerCase() +
    '/' +
    getFakeAddres(property)?.replaceAll(' ', '-')?.replaceAll('/', '-').toLowerCase() +
    '/' +
    property?.id
  )
}

export const makeTextAmenities = (property: Property) => {
  let array = []
  let text = ''
  // Ambientes
  if (property?.room_amount > 0) {
    text += property?.room_amount ? property?.room_amount + ' ambiente' : ''
    text += property?.room_amount > 1 ? 's' : ''
    array.push(text)
  }
  // Dormitorios
  text = ''
  if (property?.suite_amount > 0) {
    text += ' ' + property?.suite_amount ? property?.suite_amount + ' dormitorio' : ''
    text += property?.suite_amount > 1 ? 's' : ''
    array.push(text)
  }

  return array
}

export const makeTextBathrooms = (property: Property) => {
  let array = []
  let text = ''
  // Baños
  if (property?.bathroom_amount > 0) {
    text += ' ' + property?.bathroom_amount ? property?.bathroom_amount + ' baño' : ''
    text += property?.bathroom_amount > 1 ? 's' : ''
    array.push(text)
  }
  text = ''
  if (property?.toilet_amount > 0) {
    text += ' ' + property?.toilet_amount ? property?.toilet_amount + ' toilettes' : ''
    text += property?.toilet_amount > 1 ? 's' : ''
    array.push(text)
  }
  return array
}

export const makeTextSurfaces = (property: Property, short = false) => {
  let text = ''
  let array = []
  // Total - array index 0
  if (property?.total_surface !== '0' && property?.total_surface.toString() !== '0.00') {
    text +=
      ' ' + property?.total_surface !== '0' && property?.total_surface !== '0.00'
        ? property?.total_surface.split('.')[0] + ` m2 ${short ? '' : 'tot. construído'}`
        : ''
    array.push(text)
    text = ''
  }
  // Roofed - array index 1
  if (
    property?.roofed_surface.toString() !== '0' &&
    property?.roofed_surface.toString() !== '0.00'
  ) {
    text +=
      property?.roofed_surface !== '0' && property?.roofed_surface.toString() !== '0.00'
        ? property?.roofed_surface.split('.')[0] + ` m2 ${short ? '' : 'cubiertos'}`
        : ''
    array.push(text)
    text = ''
  }
  // Unroofed - array index 2
  if (property?.unroofed_surface !== '0' && property?.unroofed_surface !== '0.00') {
    text +=
      ' ' + property?.unroofed_surface !== '0' && property?.unroofed_surface !== '0.00'
        ? property?.unroofed_surface?.split('.')[0] + ` m2 ${short ? '' : 'descubiertos'}`
        : ''
    array.push(text)
    text = ''
  }
  // Semiroofed - array index 3
  if (property?.semiroofed_surface !== '0' && property?.semiroofed_surface.toString() !== '0.00') {
    text +=
      ' ' + property?.semiroofed_surface !== '0'
        ? property?.semiroofed_surface.split('.')[0] + ` m2 ${short ? '' : 'semicubiertos'}`
        : ''
    array.push(text)
    text = ''
  }
  // Terreno - array index 4
  if (property?.surface !== '0' && property?.surface !== '0.00') {
    text +=
      ' ' + property?.surface !== '0' && property?.surface !== '0.00'
        ? property?.surface.split('.')[0] + ` m2 ${short ? '' : 'terreno'}`
        : ''
    array.push(text)
    text = ''
  }

  return array
}

export const makeTextTags = (property: Property) => {
  let text = ''
  let array = []
  if (property?.tags) {
    let index = 0
    while (index < 4 && index < property?.tags.length) {
      text += property?.tags[index].name + ''
      index++
      array.push(text)
      text = ''
    }
  }
  return array
}

export const makeTextParking = (property: Property) => {
  let text = ''
  if (property?.parking_lot_amount > 0) {
    text += ' ' + property?.parking_lot_amount ? property?.parking_lot_amount + ' cochera' : ''
    text += property?.parking_lot_amount > 1 ? 's' : ''
  }
  return text
}

export const getBluePrints = (property: Property | Development) => {
  if (property?.photos) {
    return property?.photos.filter((photo) => photo.is_blueprint === true)
  }
  return []
}

export const getTour = (property: Property | Development) => {
  if (property?.videos) {
    return property.videos.filter(
      (video) =>
        video.provider === 'matterport' ||
        video.provider === 'hauzd' ||
        video.provider === '360magictour' ||
        video.provider === 'vista360' ||
        video.provider === 'roundme',
    )
  }
  return []
}

export const getTours = (property: Property | Development) => {
  if (property?.videos) {
    return property?.videos.filter(
      (video) =>
        video.provider === 'matterport' ||
        video.provider === 'hauzd' ||
        video.provider === '360magictour' ||
        video.provider === 'vista360' ||
        video.provider === 'roundme',
    )
  }
  return []
}

export const getVideo = (property: Property | Development) => {
  if (property?.videos) {
    const list_videos = property?.videos.filter(
      (video) => video.provider === 'youtube' || video.provider === 'vimeo',
    )
    return list_videos
  }
  return []
}

export const getLastVideo = (property: Property | Development) => {
  if (property?.videos) {
    const list_videos = property?.videos.filter(
      (video) => video.provider === 'youtube' || video.provider === 'vimeo',
    )
    return list_videos[list_videos.length - 1]
  }
  return null
}

export const getAge = (property: Property) => {
  if (property?.age === -1) {
    return 'En construcción'
  }
  if (property?.age === 0) {
    return 'A estrenar'
  }
  return property?.age ? `${property?.age} ${property?.age > 1 ? 'años' : 'año'}` : 'No informado'
}

// export const hasBarbacue = (property:Property) => {
//     // Return random boolean
//     if(property?.tags?.find(element => element.id === 35)){return true}
//     return false;
//     // 51, name: 'Pileta', type:
// }

// export const hasPool = (property:Property) => {
//     // Return random boolean
//     if(property){
//         if(property?.tags?.find(element => element.id === 51)){return true}
//     }
//         return false;
// }

export const hasTag = (property: Property, tag_id: number) => {
  // Return  boolean
  if (property) {
    if (property?.tags?.find((element) => element.id === tag_id)) {
      return true
    }
  }
  return false
}

export const isFeatured = () => {
  // Return random boolean
  return Math.random() < 0.1
}

export const getNameProducer = (property: Property) => {
  return property?.producer?.name
}

export const getPictureProducer = (property: Property) => {
  return property?.producer?.picture
}

export const getCellphoneProducer = (property: Property) => {
  return property?.producer?.cellphone
}

export const getEmailProducer = (property: Property) => {
  return property?.producer?.email
}

export const getOrientation = (property: Property) => {
  return property?.orientation
}

export const getShortOperation = (operation: string) => {
  switch (operation.toLowerCase()) {
    case 'venta':
      return 'Venta'
    case 'alquiler':
      return 'Alquiler'
    default:
      return 'Temporario'
  }
}
